@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.base-styles {
  &[data-variant='text'] {
    &:hover {
      text-decoration: underline;
    }
  }
}
