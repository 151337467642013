@use 'styles/variables';
@use 'styles/functions';

.wide-banner-paper {
  box-shadow: none;
  background-color: variables.$color-primary-highlight-blue;
  width: 100%;
  margin: functions.spacing(2.5) 0;
  border-radius: functions.spacing(1.25);
  position: relative;

  a:link {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

.wide-grid-banner {
  padding: functions.spacing(2.5);
  text-align: left;
}

.heading {
  margin-top: functions.spacing(0.5);
  margin-bottom: functions.spacing(1.5);
  color: variables.$color-neutral-text-onlight;
  width: 100%;
}

.call-to-action {
  margin-top: functions.spacing(1);
  color: variables.$color-primary-achieve-blue;

  span {
    top: 6px;
    left: 3px;
  }
}

.wide-banner-close-icon {
  position: absolute;
  margin-left: auto;
  top: functions.spacing(1.5);
  right: functions.spacing(1.5);
  height: functions.spacing(3);
  width: functions.spacing(3);
  cursor: pointer;
}

@media screen and (max-width: variables.$breakpoint-md) {
  .wide-banner-paper {
    width: 100%;
    padding: functions.spacing(1);

    a:link {
      text-decoration: none;
    }
  }

  .heading {
    width: 250px;
  }

  .wide-grid-banner {
    padding: 0;
  }

  .wide-banner-items {
    text-align: left;
    margin-right: 0;
    padding: functions.spacing(1);
  }
}
