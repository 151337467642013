/*
Importing into component.module.scss:

`@use styles/backgrounds` as bg;
  NOTE: Be sure to use @use and not @import since doing so increases the bundle
  size while @use only loads a file once

- Using a background:
  `background: bg.$color-neutral-background-medium;`
*/
@use 'styles/variables' as var;

/*
CAUTION: Some colors being used to make gradients in new design do not use Ultramarine colors.
*/
$non-standard-blue-1: #1fa1ff;

$bg-gradient-blue-1: linear-gradient(
  16.76deg,
  var.$color-primary-achieve-blue -58.13%,
  var.$color-primary-trust-light-blue 89.44%
);

$bg-gradient-purple-blue-1: linear-gradient(
  81.78deg,
  var.$color-brand-progress-purple 4.23%,
  $non-standard-blue-1 117.8%
);
