@use 'styles/variables' as var;
@use 'styles/functions' as fn;
@use 'styles/backgrounds' as bg;

.achieve-section {
  position: relative;
  overflow: hidden;

  &[data-variant='blueGradient1'] {
    background: bg.$bg-gradient-blue-1;
  }

  &[data-variant='purpleBlueGradient1'] {
    background: bg.$bg-gradient-purple-blue-1;
  }

  &[data-variant='neutralGrey8'] {
    background: var.$color-neutral-grey-8;
  }

  &[data-variant='blue'] {
    background: var.$color-primary-achieve-blue;
  }

  &[data-variant='white'] {
    background: var.$color-neutral-background-default;
  }

  &[data-variant='bgShadowPrimaryHighlightBlue'] {
    background: var.$color-primary-highlight-blue;

    &::before {
      content: '';
      position: absolute;
      left: -21.18%;
      right: 88.15%;
      top: -26.6%;
      bottom: 31%;
      background: linear-gradient(180deg, rgb(25 56 219 / 20%) 24.21%, rgb(211 86 111 / 20%) 100%);
      filter: blur(75px);
      transform: rotate(-7.42deg);
    }
  }

  &[data-variant='bgShadowPrimaryHighlightWhiteBlue'] {
    background: #f8f9ff;

    &::before {
      content: '';
      position: absolute;
      left: 45.19%;
      right: 11.45%;
      top: 9.49%;
      bottom: 1.81%;
      background: linear-gradient(
        180deg,
        rgb(25 56 219 / 20%) 14.68%,
        rgb(33 161 255 / 20%) 73.99%,
        rgb(254 206 47 / 20%) 98.44%
      );
      filter: blur(76.3648px);
      transform: rotate(-7.42deg);
    }
  }
}

.achieve-section-container {
  max-width: var.$maximum-site-content-width;
  padding-left: var.$default-gutter-width;
  padding-right: var.$default-gutter-width;

  &[data-narrow='true'] {
    max-width: var.$maximum-site-content-width-narrow;
  }
}

.achieve-pattern {
  opacity: 0.05;
  scale: 1.2;
}

@media screen and (min-width: var.$breakpoint-md) {
  .achieve-section-container {
    padding-left: var.$medium-and-up-gutter-width;
    padding-right: var.$medium-and-up-gutter-width;
  }
}
