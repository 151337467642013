@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.header {
  background: var.$color-neutral-background-default;
  box-shadow: 0 0.5px 3px rgb(16 24 40 / 9%), 0 1px 1px rgb(16 24 40 / 3%);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.header-content {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.header-content-container {
  max-width: var.$maximum-site-content-width;

  &[data-narrow='true'] {
    max-width: var.$maximum-site-content-width-narrow;
  }
}

[data-mobile-nav-open='true'] .header-content {
  transition: height 0s;
  height: 100vh;
}

.mobile-header {
  align-items: center;
  background: var.$color-neutral-background-default;
  display: flex;
  flex-direction: row;
  height: 72px;
  justify-content: space-between;
  padding-left: var.$default-gutter-width;
  // Reduced right padding accommodates for the mobile nav button touch target extra padding
  padding-right: var.$default-gutter-width / 2;
  width: 100%;
  z-index: 2;
}

[data-mobile-nav-open='true'] .mobile-header,
[data-mobile-condensed='true'] .mobile-header {
  height: 54px;
}

[data-mobile-nav-open='true'] .mobile-header {
  border-bottom: 1px solid var.$color-neutral-border-default;
}

.logo {
  height: 29px;
  width: 119px;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  transition: var.$default-animation-timing;
}

.mobile-logo {
  flex-grow: 0;
  flex-shrink: 0;
  height: 29px;
  position: absolute;
  transition: var.$default-animation-timing;
  width: 36px;
}

.mobile-header-cta {
  display: inline-block;
  position: absolute;
  right: 52px; // Adjust the cta to allow space for the menu button
  top: 10px; // Vertically center the mobile header cta within the mobile condensed header
  transition: var.$default-animation-timing;
  z-index: 2;

  button {
    transition: var.$default-animation-timing;
  }
}

/**
 * Conditionally hide header elements by sliding them vertically up out of the browser window.
 * - Hide the main logo when the mobile nav is open
 * - Hide the main logo when the header is condensed on mobile
 * - Hide the mobile header cta button when the mobile nav is open
 * - Hide the mobile header cta button when the header is not condensed on mobile
 * - Hide the mobile logo anytime the header is not condensed on mobile or the mobile nav is closed
 */
[data-mobile-nav-open='true'] .logo,
[data-mobile-condensed='true'] .logo,
[data-mobile-nav-open='true'] .mobile-header-cta,
[data-mobile-condensed='false'] .mobile-header-cta,
[data-mobile-condensed='false'][data-mobile-nav-open='false'] .mobile-logo {
  transform: translateY(-90px);
}

.mobile-menu-controls {
  align-items: center;
  display: flex;
  height: 34px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.mobile-menu-button,
.mobile-close-button {
  align-items: center;
  background: none;
  border: none;
  color: var.$color-neutral-text-onlight;
  display: flex;
  padding: 11px fn.spacing(1); // Increases the hit area of the mobile buttons
  position: absolute;
  transition: var.$default-animation-timing;
}

.mobile-close-button span {
  margin-right: fn.spacing(1.5);
  text-decoration: none;
}

[data-mobile-nav-open='true'] {
  .mobile-menu-button {
    transform: translateY(-80px);
  }
}

[data-mobile-nav-open='false'] {
  .mobile-close-button {
    transform: translateX(200px);
  }
}

.mobile-header-actions {
  background: var.$color-neutral-background-default;
  bottom: fn.spacing(-10);
  padding: fn.spacing(2);
  position: fixed;
  transition: transform var.$default-animation-timing;
  width: 100%;
  z-index: 3;
}

[data-mobile-nav-open='true'] .mobile-header-actions {
  box-shadow: 0 1px 14px rgb(0 0 0 / 12%);
  transform: translateY(-80px);
}

.mobile-header-actions-sign-in-link-container {
  width: auto;
}

.mobile-header-actions-sign-in-link {
  display: inline-block;
}

.mobile-header-actions-cta {
  display: inline-block;
  width: 100%;
  text-align: center;

  &:hover {
    text-decoration: none !important;
  }
}

.sign-in-link {
  display: none;
}

/* Desktop (large or greater) styles */
@media screen and (min-width: var.$breakpoint-lg) {
  .header {
    box-shadow: none;

    &[data-condensed='true'] {
      box-shadow: 0 6px 40px -4px rgb(16 24 40 / 11%), 0 9px 12px -3px rgb(16 24 40 / 5%);
    }
  }

  .header-content-container {
    // These paddings normally apply on the medium breakpoint, but due to custom considerations for
    // the header, they don't apply until the design switches to the large designs.
    padding-left: var.$medium-and-up-gutter-width;
    padding-right: var.$medium-and-up-gutter-width;
  }

  .mobile-header {
    background: none;
    padding: 0;
    position: relative;
    width: auto;
  }

  .mobile-logo {
    display: none;
  }

  .header-content {
    align-items: center;
    flex-direction: row;
    height: fn.spacing(12); // 96px
    justify-content: space-between;
    overflow: visible;
    transition: height var.$default-animation-timing;
  }

  // Use the default header height, ignoring if mobile nav flag is set to open
  [data-mobile-nav-open='true'] .header-content {
    height: fn.spacing(12); // 96px
  }

  // Use the condensed header height in any instance where[data-condensed='true']
  [data-condensed='true'] .header-content,
  [data-mobile-nav-open='true'][data-condensed='true'] .header-content {
    height: fn.spacing(8); // 64px
  }

  [data-mobile-nav-open='true'] .mobile-header {
    border: 0;
    height: auto;
    position: relative;
  }

  .logo {
    height: 60px;
    transform: none;
    width: 180px;
  }

  [data-mobile-condensed='true'] .logo,
  [data-mobile-nav-open='true'] .logo {
    transform: none;
  }

  [data-condensed='true'] .logo {
    height: 52px;
    width: 117px;
  }

  .header-cta {
    display: inline-block;

    &:hover {
      text-decoration: none;
    }

    button {
      transition: all var.$default-animation-timing;
    }
  }

  .mobile-menu-controls {
    display: none;
  }

  .mobile-menu-button,
  .mobile-close-button {
    display: none;
  }

  .sign-in-link {
    display: inline-block;
    margin-left: fn.spacing(4);
  }
}
