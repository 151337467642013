@use 'styles/functions' as fn;

.iframe-wrapper {
  display: flex;
  width: 100vw;
  justify-content: center;
  margin-bottom: fn.spacing(12);
}

.iframe-wrapper-external {
  width: 100%;
  height: 100%;
}

.video-player-embed {
  position: relative;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
